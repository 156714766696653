import { useEffect } from "react";

import { getHintUtils } from "@epic-web/client-hints";
import { clientHint as timezoneHint } from "@epic-web/client-hints/time-zone";
import { useRevalidator, useRouteLoaderData } from "@remix-run/react";
import { isUndefined, omitBy } from "lodash-es";
import { ClientHintsValue } from "node_modules/@epic-web/client-hints/dist/utils";

import {
  clientHint as mobileHint,
  subscribeToMobileChange,
} from "~/hooks/useMobileView";
import { type loader as rootLoader } from "~/root";

const timezoneOffestHint = {
  cookieName: "CH-time-zone-offset",
  getValueCode: "new Date().getTimezoneOffset()",
  fallback: "0",
  transform: (value: string) => parseInt(value, 10),
};

const hints = {
  timezone: timezoneHint,
  timezoneOffset: timezoneOffestHint,
  mobile: mobileHint,
};

const hintsUtils = getHintUtils(omitBy(hints, isUndefined) as typeof hints);

export const { getHints } = hintsUtils;

/**
 * @returns the request info from the root loader
 */
export function useHints(): ClientHintsValue<typeof hints> | null {
  const data = useRouteLoaderData("root") as Awaited<
    ReturnType<Awaited<ReturnType<typeof rootLoader>>["json"]>
  >;
  if (!data?.requestInfo?.hints) {
    return null;
  }

  return data.requestInfo.hints;
}

export function ClientHintCheck({ nonce }: { nonce: string }) {
  const { revalidate } = useRevalidator();
  useEffect(() => subscribeToMobileChange(revalidate), [revalidate]);

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
    />
  );
}
